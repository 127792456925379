import React, { createContext, useEffect, useState } from 'react'
import config from '../../config.js';
import useGetRequest from '../../hooks/useGetRequest';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Stack } from '@mui/material';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const { sendGetRequest, loading } = useGetRequest();
    const navigate = useNavigate();
    
    const verifyToken = async () => {
        try {
            const url = `${config.rootEndpoint}/auth/verifyToken/`;
            const token = localStorage.getItem('token');
            const options = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }

            await sendGetRequest(url, options);
            setIsLoggedIn(true);

        } catch(error) {
            navigate("/login");
        }
    }

    useEffect(() => {
        verifyToken();
    }, []);

    if(loading) {
        return (
            <Stack justifyContent={"center"} alignItems={"center"} height={"90vh"}>
                <CircularProgress />
            </Stack>
        )
    }

    return (
        <AuthContext.Provider value = {{ isLoggedIn, setIsLoggedIn, loading }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;
export { AuthContext };