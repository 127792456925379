import { Button, Stack, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { toast } from "react-toastify";
import config from '../../../config';
import usePostRequest from "../../../hooks/usePostRequest.js";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../layouts/AuthProvider.jsx';

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { sendPostRequest, loading } = usePostRequest();
    const auth = useContext(AuthContext);

    const navigate = useNavigate();

    const login = async (event) => {
        event.preventDefault();

        if(!username || !password) {
            toast.error("Kindly enter username and password");
            return;
        }

        try {
            const url = `${config.rootEndpoint}/auth/login/`;
            const payload = { email: username, password };

            const response = await sendPostRequest(url, payload);
            const { token } = response.data;

            localStorage.setItem('token', token);
            auth.setIsLoggedIn(true);
            navigate("/");

        } catch(error) {
            console.log(error);
            
            if(error.response?.status === 401) {
                toast.error("Invalid email or password");
            } 
            else {
                const message = error.response?.data?.message || "Something went wrong";
                toast.error(message);
            }
        } 
    }

    return (
        <Stack height={"80vh"} justifyContent={"center"} alignItems={"center"}>
            <form 
                onSubmit={login}
                style={{ 
                    display: "flex", 
                    flexDirection: "column", 
                    gap: "16px", 
                    alignItems: "center",
                    borderRadius: "32px"
                }} 
                padding={4} 
            >

                <h1>Login</h1>

                <TextField 
                    sx={{ minWidth: "300px" }}
                    variant='outlined'
                    label='username'
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                />
                <TextField 
                    sx={{ minWidth: "300px" }}
                    variant='outlined'
                    label='password'
                    value={password}
                    type='password'
                    onChange={e => setPassword(e.target.value)}
                />

                <Button type='submit' variant="contained" disabled={loading}>
                    {loading ? "Logging in..." : "Log in"}
                </Button>
            </form>
        </Stack>
  )
}

export default Login