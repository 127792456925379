import axios from 'axios';
import { Modal, Button, Box, TextField, Select, MenuItem } from '@mui/material';
import { useState } from 'react';
import "./DisciplineModal.css";
import { toast } from 'react-toastify';

const DisciplineModal = ({currentDiscipline, currentCourse, modalOpen, setModalOpen, endpoint, method}) => {
    const [discipline, setDiscipline] = useState(currentDiscipline);
    const [course, setCourse] = useState(currentCourse);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
    };

    const handleInputChange = (value, updaterFunction) => {
        updaterFunction(value);
    }

    const createDiscipline = async (discipline, course, endpoint, method) => {
        try {

            if(!discipline || !course){
                alert('Please fill all the details!');
                return;
            }

            const data = {
                discipline,
                course
            }

            const token = localStorage.getItem('token');
            
            await axios({
                method,
                url: endpoint,
                data,
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            window.location.reload();

        } catch (error) {
            const message = error.response?.data?.message || "Something went wrong";
            toast.error(message);
        }
    }

    return(
        <>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

            <Box sx={style} spacing={2} component="form">
                <h1 className="headline">Create Discipline</h1>

                <TextField 
                    id="outlined-basic" 
                    label="Discipline Name" 
                    variant="outlined" 
                    value={discipline}
                    onChange={(e) => handleInputChange(e.target.value, setDiscipline)}
                    required
                />
            
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={course}
                    label="Course"
                    onChange={(e) => handleInputChange(e.target.value, setCourse)}
                >
                    <MenuItem value="B.A (H)">B.A (H)</MenuItem>
                    <MenuItem value="B.A (Prog)">B.A (Prog)</MenuItem>
                    <MenuItem value="B.Sc (H)">B.Sc (H)</MenuItem>
                    <MenuItem value="B.Sc (Prog)">B.sc (Prog)</MenuItem>
                    <MenuItem value="Others">Others</MenuItem>
                </Select>

                <Button 
                    style={{backgroundColor: 'cadetblue', color: 'black'}} 
                    variant="contained"
                    onClick={() => createDiscipline(discipline, course, endpoint, method)}
                >
                    {method === 'POST' ? 'Create Discipline' : 'Update Discipline'}
                </Button>
            </Box>

            </Modal>
        </>
    )
}

export default DisciplineModal;