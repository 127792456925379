import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import axios from "axios";
import config from "../../../../config";
import { toast } from "react-toastify";
import Header from "../../../layouts/Header";
import "./Subjects.css";

const SubjectCard = ({name, subjectID}) => {

    return (
        <Card sx={{ minWidth: 275, backgroundColor: "floralwhite" }}>
            <CardContent style={{textAlign: "center"}}>
                {name}
            </CardContent>
            <CardActions 
                style={{justifyContent: "center"}}
            >
                <Link to={`./syllabus?id=${subjectID}&name=${name}`}>
                    <Button size="small">Syllabus</Button>
                </Link>
                <Link to={`./notes?id=${subjectID}&name=${name}`}>
                    <Button size="small">Notes</Button>
                </Link>
                <Link to={`./pyq?id=${subjectID}&name=${name}`}>
                    <Button size="small">Pyq</Button>
                </Link>
            </CardActions>
        </Card>
    )
}

const SubjectsAccordion = ({item}) => {
    return (
        <Accordion
            defaultExpanded={item.type === "GE" ? true : false}
            sx={{backgroundColor: 'cadetblue'}}
        >
            <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                <Typography>{item.type}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box
                    display="flex"
                    gap={4}
                    flexWrap="wrap"
                >
                    {item.subjects.map((subject) => {
                        return (
                            <SubjectCard 
                                key={subject.id}
                                name={subject.name}
                                subjectID={subject.id}
                            />
                        )
                    })}
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

const SubjectModal = () => {
    const [open, setOpen] = useState(false);
    const [type, setType] = useState("GE");
    const [subjectName, setSubjectName] = useState("");

    const createSubject = async () => {
        try {
            const endpoint = `${config.rootEndpoint}/optionals/subjects/`;
            const data = { subjectName, type };
            const token = localStorage.getItem('token');
            const options = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }

            await axios.post(endpoint, data, options);
            window.location.reload();

        } catch (error) {
            const message = error.response?.data?.message || 'Something went wrong';
            toast.error(message);
        }
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        gap: 2
      };

    return (
        <div>
            <Button className="btn-secondary" style={{color: "black"}} variant="contained" onClick={handleOpen}>Add Subject</Button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Stack sx={style}>
                    <h3 style={{textAlign: "center"}}>Add Subject</h3>

                    <TextField 
                        id="outlined-basic"
                        variant="outlined" 
                        label="Subject Name" 
                        sx={{width: '100%'}}
                        value={subjectName}
                        onChange={(e) => setSubjectName(e.target.value)}
                    />

                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        sx={{width: '100%'}}
                    >

                        <MenuItem disabled>--type--</MenuItem>
                        <MenuItem value="GE">GE</MenuItem>
                        <MenuItem value="SEC">SEC</MenuItem>
                        <MenuItem value="VAC">VAC</MenuItem>
                        <MenuItem value="AECC">AECC</MenuItem>
                        
                    </Select>

                    <Button className="btn-primary" variant="contained" onClick={createSubject}>Create Subject</Button>
                </Stack>
            </Modal>
        </div>
    )
}

const Subjects = () => {

    const [subjects, setSubjects] = useState([]);

    const getSubjects = async () => {
        try {
            const response = await axios.get(`${config.rootEndpoint}/optionals/subjects/`);
            return response.data;

        } catch (error) {
            return [];
        }
    }

    useEffect(() => {
        (async () => {
            const data = await getSubjects();

            setSubjects(data);
        })();
    }, []);

  return (
    <>
       <Header heading={"Optional Subjects"} />

        <Box
            display="flex"
            flexDirection="column"
            padding={2}
            rowGap={2}
        >

            <SubjectModal />

            <div>
                {subjects.map((item) => {
                    return (
                        <SubjectsAccordion
                            key={item.type}
                            item={item}
                        />
                    )
                })}
            </div>
        </Box>
    
    </>
  )
}

export default Subjects;