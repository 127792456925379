import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import config from "../../../../config";
import Content from "../../../layouts/Content/Content";
import "./Notes.css";
import NotesWrapperCuetUg from "../../../layouts/NotesWrapperCuetUg/NotesWrapperCuetUg";

const Notes = () => {
    const [notes, setNotes] = useState({});
    const [loading, setLoading] =  useState(true);
    const [searchParams] = useSearchParams();

    const fetchNotes = async () => {
        try {
            const subjectID = searchParams.get('id');
            const endpoint = `${config.rootEndpoint}/cuet-ug/notes/?id=${subjectID}`;
    
            const response = await axios.get(endpoint);
    
            return response.data;
            
        } catch (error) {
            return {};
        }
    }

    useEffect(() => {

        (async () => {
            const data = await fetchNotes();

            setNotes(data);

            setLoading(false);
        })();

    }, []);

    if(loading){
        return <div>Loading...</div>
    }

    else if(Object.keys(notes).length === 0){
        return <div>An error occured. Please try again later!</div>
    }
    

    return (
        <div className="notesUG">

            <div className="header">
                <h1>{searchParams.get('name')} Notes</h1>
            </div>

            <Content 
                contentDetails={notes.content}
                category='notes'
                type='cuet-ug'
            />

            <NotesWrapperCuetUg 
                notes={notes}
                type='cuet-ug'
            />

        </div>
    )
}

export default Notes;