import { Link } from "react-router-dom";
import "./Homepage.css";

const Homepage = () => {
    return (
        <div className="homepage" style={{ padding: "32px" }}>
            <div className="sectionsWrapper">
                <Link to="./du/cuet-ug/subjects" className="section">CUET UG</Link>
                <Link to="./du/ug/courses" className="section">Under Graduate</Link>
                <Link to="./du/optionals/subjects" className="section">Optional Subjects</Link>
                <Link to="./blogs/all" className="section">Blogs</Link>
            </div>
        </div>
    )
}

export default Homepage;