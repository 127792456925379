import { useState } from "react";
import ContentEditor from "../ContentEditor/ContentEditor";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import "./ContentNew.css";
import { toast } from "react-toastify";

const ContentNew = ({ category, endpoint}) => {
    const [contributor, setContributor] = useState("");
    const [content, setContent] = useState("");
    const [isAdding, setIsAdding] = useState(false);
    const [searchParams] = useSearchParams();

    const addContent = async () => {
        try{

            const id = searchParams.get('id');
            const semester = searchParams.get('sem');

            const data = {
                content,
                contributor,
                id,
                category,
                semester
            }
            const token = localStorage.getItem('token');
            const options = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }

            await axios.post(endpoint, data, options);
            window.location.reload();

        }catch(error){
            toast.error(`Failed! ${error.response.data.message}`);
        }
    }

  return (
    <div className="element">
        {isAdding ? (
            <div style={{display: 'flex', flexDirection: 'column', gap: '24px'}}>
                <ContentEditor 
                    content={content}
                    setContent={setContent}
                />

                <div className="form">
                    <input placeholder="Contributor" type="text" value={contributor} onChange={(e) => setContributor(e.target.value)} />

                    <button className="btn-primary" onClick={addContent}>Add Content</button>
                </div>
            </div>
        ) : (
            <div className="noContentContainer">
                <p>No content is added yet!</p>
                <button className="btn-primary" onClick={() => setIsAdding(true)}>Add Content</button>
            </div>
        )}
    </div>
  )
}

export default ContentNew;