import BlogEditor from "../../../layouts/BlogEditor/BlogEditor";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import config from "../../../../config.js";
import axios from "axios";
import { Box, Button, Stack } from "@mui/material";


const CreateBlog = () => {

    const navigate = useNavigate();

    const handleSubmit = async (data) => {
        try {
            delete data.blogId;
            const token = localStorage.getItem('token');
            const options = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }

            const response = await axios.post(`${config.rootEndpoint}/blogs/`, data, options);

            if(response.status === 201) {
                toast.success("Blog created successfully!");
                navigate('/blogs/all');
            }

        } catch(error) {
            toast.error(`Failed. ${error.response?.data?.message}`);
        }
    }

    return (
        <Stack>
            
            <Box padding={2}>
                <Button 
                    variant="contained" 
                    startIcon={<ArrowBackIcon />} 
                    onClick={() => navigate('/blogs/all')}
                >
                    All Blogs
                </Button>
            </Box>

            <BlogEditor 
                title=""
                description=""
                coverImage=""
                content=""
                category="" 
                author=""
                tagList={[]}
                handleSubmit={handleSubmit}
                buttonName="Create"
            />
        </Stack>
    )
}

export default CreateBlog;