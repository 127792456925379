import { Stack } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

import Content from "../../../layouts/Content/Content";
import NotesWrapperCuetUg from "../../../layouts/NotesWrapperCuetUg/NotesWrapperCuetUg";
import config from "../../../../config";

import "./Notes.css";

const Notes = () => {

    const [notes, setNotes] = useState({});
    const [searchParams] = useSearchParams();

    const getNotes = async () => {
        try{
            const id = searchParams.get('id');
            const sem = searchParams.get('sem');

            const endpoint = `${config.rootEndpoint}/ug/notes/?id=${id}&sem=${sem}`
            
            const response = await axios.get(endpoint);

            return response.data;

        }catch(error){
            return {};
        }
    }

    useEffect(() => {
        (async () => {
            const data = await getNotes();
            setNotes(data);
        })();
    }, []);

    return (
        <div className="notesPage">
            <div className="header">
                <h1>{searchParams.get('discipline')} Notes</h1>
            </div>

            <Stack spacing={6} sx={{padding: '32px'}}>
                <Content
                    contentDetails={notes.content}
                    category='notes'
                    type='ug'
                />

                <NotesWrapperCuetUg 
                    notes={notes}
                    type='ug'
                />
            </Stack>
        </div>
    )
}

export default Notes;