import axios from "axios";
import { useState } from "react";

const useGetRequest = () => {
    const [loading, setLoading] = useState(false);

    const sendGetRequest = async (url, options = {}) => {
        try {
            setLoading(true);
            const response = await axios.get(url, options);
            return response;
        }
        finally {
            setLoading(false);
        }

    }

    return { sendGetRequest, loading };
}

export default useGetRequest;