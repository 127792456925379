import ContentEditor from "../ContentEditor/ContentEditor";
import { useState } from "react";
import axios from "axios";
import "./ContentUpdate.css";
import { toast } from "react-toastify";

const ContentUpdate = ({contentDetails, endpoint}) => {
    const [content, setContent] = useState(contentDetails.content);
    const [contributor, setContributor] = useState(contentDetails.contributor);

    const updateContent = async () => {
        try{
            const data = {
                content,
                contributor,
                id: contentDetails.contentID
            }

            const token = localStorage.getItem('token');
            const options = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }

            await axios.put(endpoint, data, options);
            window.location.reload();

        }catch(error){
            toast.error(`Failed! ${error.response.data.message}`);
        }
    }

    return (
        <div className="contentUpdate element">
            <ContentEditor 
                content={content}
                setContent={setContent}
            />

            <div className="form">
                <input placeholder="Contributor" type="text" value={contributor} onChange={(e) => setContributor(e.target.value)} />

                <button className="btn-primary" onClick={updateContent}>Update Content</button>
            </div>
        </div>
    )
}

export default ContentUpdate;