import { Stack } from '@mui/material'
import React from 'react'

const Header = ({ heading }) => {

    return (
        <Stack
            sx={{justifyContent: 'center', alignItems: 'center'}}
            p={4}
            mb={4}
        >
            <h1>{heading}</h1>
        </Stack>
    )
}

export default Header