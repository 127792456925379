import axios from "axios";
import { useState } from "react";

const usePostRequest = () => {
    const [loading, setLoading] = useState(false);

    const sendPostRequest = async (url, payload, options = {}) => {
        try {
            setLoading(true);
            const response = await axios.post(url, payload, options);
            return response;
        } 
        finally {
            setLoading(false);
        }

    }

    return { sendPostRequest, loading };
}

export default usePostRequest;