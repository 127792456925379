import { Button, CircularProgress, IconButton } from '@mui/material'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from './AuthProvider.jsx';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

const Navbar = () => {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);

    const logout = () => {
        localStorage.removeItem('token');
        auth.setIsLoggedIn(false);
        navigate("/login");
    }

    return (
        <nav className="navbar" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

            {window.location.pathname !== '/' && (
                <IconButton aria-label="delete" onClick={() => navigate(-1)}>
                    <ArrowCircleLeftIcon fontSize='large' sx={{ color: "white" }}/>
                </IconButton>
            )}


            <p className="title">DUStudentHelper Admin</p>

            {auth.loading ? <CircularProgress sx={{ color: 'white' }} /> : (
                <>
                    {auth.isLoggedIn ? (
                        <Button sx={{ color: "white" }} onClick={logout}>
                            Logout
                        </Button>
                    ) : (
                        <Button sx={{ color: "white" }} onClick={() => navigate('/login')}>
                            Log in
                        </Button>
                    ) }
                </>
            )}

        </nav>
    )
}

export default Navbar