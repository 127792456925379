import axios from "axios"

const fetchSyllabusDetails = async (url) => {
    const response = await axios.get(url);
    return response.data;
}

const updateSyllabusLink = async (url, id, link) => {
    const payload = { id, link };
    const token = localStorage.getItem('token');
    const options = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    await axios.put(url, payload, options);
}

const addSyllabusLink = async (url, id, link) => {
    const payload = { id, link };
    const token = localStorage.getItem('token');
    const options = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    await axios.post(url, payload, options);
}

export { fetchSyllabusDetails, updateSyllabusLink, addSyllabusLink };