import axios from "axios";
import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Loader from "../../../layouts/Loader/Loader";
import Fallback from "../../../layouts/Fallback/Fallback";
import BlogEditor from "../../../layouts/BlogEditor/BlogEditor"
import config from "../../../../config";
import { Box, Button } from "@mui/material";


const EditBlog = () => {
    const [blogDetails, setBlogDetails] = useState({});
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);

    const { title, description, content, category, author, tags, coverImage } = blogDetails;
    const tagList = tags ? tags.split(", ") : [];
    const blogId = searchParams.get('id');
    const navigate = useNavigate();

    const fetchBlogDetails = async () => {
        try {
            const response = await axios.get(`${config.rootEndpoint}/blogs/?id=${blogId}`);
            return response.data;

        } catch(error) {
            return {};
        }
    }

    const handleSubmit = async (data) => {
        try {
            const token = localStorage.getItem('token');
            const options = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            
            const response = await axios.put(`${config.rootEndpoint}/blogs/`, data, options);

            if(response.status === 204) {
                toast.success("Blog Updated Successfully!");
                navigate(`/blogs?id=${blogId}`);
            }
        } catch(error) {
            toast.error(`Failed. ${error.response?.data?.message}`);
        }
    }

    useEffect(() => {
        const getBlogDetails = async () => {
            const data = await fetchBlogDetails();
            setBlogDetails(data);
            setLoading(false);
        }

        getBlogDetails();
    }, []);

    if(loading) {
        return <Loader text="Loading..."/>
    }

    else if(!blogDetails || Object.keys(blogDetails).length === 0) {
        return <Fallback text="Blog not found"/>
    }

    return (
        <>
            <Box padding={2}>
                <Button
                    variant="contained" 
                    startIcon={<ArrowBackIcon />} 
                    onClick={() => navigate(`/blogs?id=${blogId}`)}
                >
                    Go to blog
                </Button>
            </Box>

            <BlogEditor
                blogId={blogId}
                title={title}
                description={description}
                coverImage={coverImage}
                content={content}
                category={category}
                tagList={tagList}
                author={author}
                handleSubmit={handleSubmit}
                buttonName="Save"
            />
        </>
    )
}

export default EditBlog;